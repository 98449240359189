// primary colors
$white: #ffffff;
$black: #000000;
$irispurple :#8357eb;
$lightyellow:#ffed99;
$orange: #ea6d2e;
$titanwhite: #d7c5d8;
$lavender: #d3d1f5;
$lavenderblue: #c1b8ff;
$bluechalk: #fbf8fc;
$carrot: #fb953c;
$mango: #f8c659;

// font sizes
$font-sm: .875rem;
$font-med: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.5rem;
$font-xxl: 9rem;

// basic layout 
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-size: .9375rem;
}

