@import "../../global.scss";

.contact {
    margin-top: 6.25rem;
    display: flex;
    justify-content: space-between;

    .left {
       position: absolute;
       left: 1.875rem;
    }

    .middle {
        position: absolute;
        top: 4.375rem;
        right: 35.5rem;
        color: $irispurple;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3.125rem;

        h2 {
            font-size: 2.5rem;
        }

        .socialMedia {
            font-size: 2rem;
            display: flex;
            flex-direction: column;
            margin: .9375rem;
            gap: 20px;

            a {
                text-decoration: none;
                color: $irispurple;
            }
        }
    }

    .right {
        position: absolute;
        top: 9.375rem;
        right: 1.875rem
    }
}

@media (max-width: 768px) {
    .project {
        position: relative;
    }
    .left {
        display: none;
    }
    .right {
        display: none;
    }

    .middle {
        position: absolute;
        left: 12.5rem;
        top: 15%;

        h2 {
            color: $mango;
            width: 100vw;
        }
    }
}