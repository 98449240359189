@import "../../global.scss";

.about {
    display: flex;
    position: relative;
    top: 100px;
    background-color: $bluechalk;
    color: $lavenderblue;
    width: 100%;
    height: 80vh;

    .aboutme {
        width: 27.8125rem;
        padding-top: 1.875rem;
        padding-left: 5rem;
        line-height: 1.5;

        .highlight {
            text-decoration: none;
            color: $carrot;
        }
    }

    .right {
        position: absolute;
        top: 10%;
        right: 10%;
        width: 700px;
        height: 500px;

        .imageone {
            width: 350px;
            height: 15.625rem;
            background-image: url(../../assets/shan2.JPG);
            background-size: cover;
        }

        .imagetwo {
            position: absolute;
            right: 0;
            width: 350px;
            height: 250px;
            background-image: url(../../assets/shanImg2.jpg);
            background-size: cover;
        }
    }
}

@media (max-width: 768px)  {

    .about {
        position: absolute;
        left: -3.125rem;
        top: 7.8125rem;
        height: fit-content;
        width: 100%;
        font-size: .75rem;
        padding-right: .9375rem;
        display: flex;
    }

    .right {
        display: flex;
        flex-direction: column;

        .imageone {
          display: none;
        }

        .imagetwo {
            display: none;
        }
    }
}