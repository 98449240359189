@import "../../global.scss";

.footer {
    color: $irispurple;
    position: absolute;
    font-size: 1.25rem;
    right: .625rem;
    bottom: .625rem;

    .colorOption {
        position: absolute;
        left: -50px;
        bottom: -.625rem;
        font-size: 2rem;
        cursor: pointer;
    }
}