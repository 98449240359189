@import "../../global.scss";

.navbar {
    position: fixed;
    top: 0;
    z-index: 30;
    width: 100%;
    height: 4.375rem;
    background-color: $white;
    color: $irispurple;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wrapper {
        padding: .625rem 1.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .logo {
                font-size: 1.5rem;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 2.5rem;
            }
        }

            .right {
                position: absolute;
                right: 1.25rem;


                li {
                    position: relative;
                    display: inline;
                    list-style: none;
                    margin-right: 0.625rem;
                    padding: 0 0.625rem;
                    font-size: 1.25rem;
                    font-weight: 600;

                    a {
                        text-decoration: none;
                        background-image: linear-gradient(
                            to right,
                             #dd888d,
                             #dd888d 50%,
                             $irispurple 50%
                        );
                        background-size: 200% 100%;
                        background-position: -100%;
                        display: inline-block;
                        padding: 0.3125rem 0;
                        position: relative;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        transition: all 0.5s ease-in-out;

                        &:hover {
                            background-position: 0;

                            &::before {
                                width: 100%;
                            }
                        }

                        &::before {
                            content: "";
                            background: #dd888d;
                            display: block;
                            position: absolute;
                            bottom: -3px;
                            left: 0;
                            width: 0;
                            height: 3px;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }
    }

    .mobilelinks {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        font-size: 2rem;
        cursor: pointer;
        display: none;
    }
}


@media (min-width: 769px) {
    .mobilenavbar {
        display: none;
    }
    .mobilemenu {
        display: none;
    }
}

@media (max-width: 768px) {

    .logo {
        font-size: 1rem;
    }

    .right {
        position: absolute;
        bottom: 0;
        display: flex;

        .links {
            display: none;
        }

        .mobilelinks {
            display: block !important;
            position: absolute;
            top: 3.125rem;
            display: none;
        }
    }
    
    .mobilenavbar {
        position: absolute;
        top: 3.125rem;
        left: -10px;


        ul {
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }

        li {
            list-style: none;
            font-size: 1rem;
            background-color: aliceblue;
        }

        a {
            text-decoration: none;
            color: $irispurple;
        }

    
    }

    .mobilemenu {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 2rem;
        // display: none;
    }
    }