@import "../../global.scss";

.project {
    position: relative;
    top: 5rem;
    background-color: $bluechalk;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .container {
        width: 70%;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-self: center;
        place-self: center;
        margin-top: -10px;

        .item {
            position: relative;
            color: $titanwhite;
            width: 500px;
            height: 350px;
            margin: 0 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            cursor: pointer;

            h3, p, span {
                position: absolute;
            }

            a {
                text-decoration: none;
                color: $white;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                z-index: 1;
            }

            &:hover {
                background-color: $irispurple;
                border-radius: 1.25rem;

                h3 {

                    font-size: 1.5rem;
                }

                p {
                    color: $lightyellow;
                    position: absolute;
                    bottom: 6.25rem;
                }

                span {
                    position: absolute;
                    bottom: 1.875rem;
                    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
                    border: none;
                    color: $white;
                    padding: 0 .3125rem;
                    margin-left: .2656rem;
                    border-radius: .3125rem;
                    transition: all 0.3s ease;
                    outline: none;
                    cursor: pointer;
                

                    &:hover {
                        background: $mango;
                        transition: all 1s ease;
                    }
                }

                img {
                    opacity: 0.3;
                    z-index: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .project {
       height: fit-content;

     .container {
        width: fit-content;
        margin: 0 auto;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        .item {
           width: 380px;
           margin: 0 auto;

           img {
            object-fit: contain;
           }
        }
     }
    }  

}
